import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_ID, NgModule, isDevMode, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { TranslationModule } from './modules/translation/translation.module';
import { PipesModule } from './pipes/pipes.module';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { globalReducers } from './store/reducers';
import { ThemeModule } from './theme/theme.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    PipesModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    TranslationModule,
    NgbModule,
    ThemeModule,
    AngularSvgIconModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPhotoEditorModule,
    RecaptchaV3Module,
    NgxLoadingModule.forRoot({
      primaryColour: 'var(--neutral-11)',
      secondaryColour: 'var(--primary-01)',
      backdropBorderRadius: '3px',
      fullScreenBackdrop: true,
    }),
    StoreModule.forRoot(globalReducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    UpperCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    { provide: APP_ID, useValue: 'serverApp' },
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
