import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { apiConfig } from 'src/app/configs/api.config';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) { }

  /** CHECK: User has role
   * @param {string[]} roles list of roles to check against the user's active role
   */
  userHasRole(roles?: string[]): boolean | string {
    const userActiveRole = this.authService.loginData?.data?.user?.active_role?.role;

    if (roles) {
      const foundRole = roles.find((role) => role == userActiveRole?.name);
      if (foundRole) return foundRole;
      else return false;
    } else return false;
  }

  /** GET: Fetch all roles
   */
  getRoles(): Promise<any> {
    const url = `${apiConfig.base}/roles`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }
}
