import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlugifyPipe } from './slugify.pipe';
import { TitleCaseExtendedPipe } from './title-case-extended.pipe';

@NgModule({
    declarations: [TitleCaseExtendedPipe, SlugifyPipe],
    exports: [TitleCaseExtendedPipe, SlugifyPipe],
    imports: [CommonModule],
})
export class PipesModule { }
