import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy.service';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'events',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator', 'participant', 'event_manager'] },
    loadChildren: () =>
      import('./pages/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'entries',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['participant'] },
    loadChildren: () =>
      import('./pages/entries/entries.module').then((m) => m.EntriesModule),
  },
  {
    path: 'enquiries',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/enquiries/enquiries.module').then(
        (m) => m.EnquiriesModule
      ),
  },
  {
    path: 'participants',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator', 'event_manager'] },
    loadChildren: () =>
      import('./pages/participants/participants.module').then(
        (m) => m.ParticipantsModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator', 'participant', 'charity', 'event_manager', 'developer'] },
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator', 'participant', 'charity', 'event_manager', 'developer'] },
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'redirects',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/redirects/redirects.module').then(
        (m) => m.RedirectsModule
      ),
  },
  {
    path: 'posts',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/posts/posts.module').then((m) => m.PostsModule),
  },
  {
    path: 'support',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['participant'] },
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'cart',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['participant'] },
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'wishlist',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['participant'] },
    loadChildren: () =>
      import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['administrator', 'participant', 'account_manager', 'developer', 'charity', 'event_manager'],
    },
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'partners',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator', 'developer'] },
    loadChildren: () =>
      import('./pages/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'tickets',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/tickets/tickets.module').then((m) => m.TicketsModule),
  },
  {
    path: 'media',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/media/media.module').then((m) => m.MediaModule),
  },
  {
    path: 'race',
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['administrator'] },
    loadChildren: () =>
      import('./pages/raceinfo/raceinfo.module').then((m) => m.RaceinfoModule),
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./pages/test/test.module').then((m) => m.TestModule),
  },
  // {
  //   path: 'redirect',
  //   loadChildren: () =>
  //     import('./pages/redirect/redirect.module').then((m) => m.RedirectModule),
  // },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ]
})
export class AppRoutingModule { }
