import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { apiConfig } from 'src/app/configs/api.config';
import { socialsConfig } from 'src/app/configs/socials.config';
import { Convert, Login } from 'src/app/models/login.model';
import { CoreService } from '../core.service';
import { RecaptchaService } from '../recaptcha.service';
import { ApiService } from './api.service';

declare var google: any;
declare var FB: any;
declare global {
  interface Window {
    fbAsyncInit: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loginData!: Login;
  authSubject = new Subject<Login>();

  private _platformData: string = '';

  constructor(
    private core: CoreService,
    private apiService: ApiService,
    private recaptchaService: RecaptchaService) {
    if (core.isBrowser) {
      window.onload = function () {
        google.accounts.id.initialize({
          client_id: socialsConfig.google.clientKey,
          auto_select: false,
          scope: socialsConfig.google.configOptions.scopes
        });
      }

    }
  }

  get loginData(): Login {
    if (!this.core.isBrowser) return {} as Login

    const loginData = localStorage.getItem('login')

    if (loginData) {
      return Convert.toLogin(loginData);
    }
    return this._loginData;
  }
  set loginData(data: Login) {
    this._loginData = data;
    this.authSubject.next(data);
    localStorage.setItem('login', Convert.loginToJson(data));
  }

  onLogin(): Observable<Login> {
    return this.authSubject.asObservable();
  }

  onLogout() {
    localStorage.removeItem('login');
  }

  /** POST: Log user In
   * @param {any} requestBody Request bodys
   */
  login(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/auth/login`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  loginWithFacebook(callBack?: (data: any) => void) {
    FB.login(function (response: any) {
      if (response.authResponse) {
        if (callBack) callBack({ social_auth: { source: 'facebook', token: response.authResponse.accessToken } });
      } else {
        console.log('User cancelled login or failed.');
      }
    }, { scope: 'email' }); // Optional: Request specific permissions (e.g., email)
  }

  loginWithGoogle(callBack?: (data: any) => void) {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: socialsConfig.google.clientKey,
      scope: socialsConfig.google.configOptions.scopes,
      callback: (data: any) => {
        callBack && callBack({ social_auth: { source: 'google', token: data.access_token } });
      },
    });
    client.requestAccessToken()
  }

  /** POST: Register a new user In
   * @param {any} requestBody Request bodys
   */
  register(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/auth/register`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Verify account after registration
   * @param {any} requestBody Request bodys
   */
  verifyAccount(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/account/verify?type=email`;
    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Resend verification code to user
   */
  async resendVerificationCode(): Promise<any> {
    const url = `${apiConfig.base}/account/verification/resend?type=email`;
    const token = await this.recaptchaService.executeRecaptchaV3('sendResetCode');
    const body: any = {
      recaptcha_token: token,
    };

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Account Password Set up
   * @param {any} requestBody Request bodys
   */
  setupAccountPassword(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/account/password/create`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Resend Password Set up Code
   * @param {any} requestBody Request bodys
   */
  resendSetupCode(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/account/password/resend-setup-code`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Send a reset code to the given user who forgot their password.
   * @param {any} requestBody Request bodys
   */
  requestResetCode(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/password/email`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Complete the password reset request initiated by the user.
   * @param {any} requestBody Request bodys
   */
  resetPassword(requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/password/reset`;
    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** POST: Log user Out
   * @param {string} all If the User wants to revoke all their active access tokens.
   */
  logout(all?: string): Promise<any> {
    const url = `${apiConfig.base}/auth/logout`;

    const body: any = {};

    // Any parameter is passed only if it is not empty or null
    if (all && !this.core.isEmptyOrNull(all)) {
      body.all = all;
    }

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** ANONYMOUS USER */

  /** GET: Generate Unique Identifier for Request API Request User. The returned value will be used to set X-Platform-User-Identifier-Key on header.
   */
  generateIdentifier(): Promise<any> {
    const url = `${apiConfig.base}/get-identifier-token`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  get platformUser(): string {
    // if (!this.core.isBrowser) return ''
    const identifier = localStorage.getItem('platform');
    if (identifier) return JSON.parse(identifier).user.identifier;
    else return this._platformData;
  }

  set platformUser(value: any) {
    this._platformData = value.user.identifier;
    localStorage.setItem('platform', JSON.stringify(value));
  }

  loginViaWebsite(ref:any): Promise<any> {
    const url = `${apiConfig.base}/auth/${ref}/login_via_website`;
    return this.apiService.makePostRequest(url, '');
  }
}
