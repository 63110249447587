import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from '../services/api/roles.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private rolesService: RolesService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.rolesService.userHasRole(route.data['roles'])) return true;

    // navigate to not found page
    this.router.navigate(['/error/404'], {
      queryParams: route.data['redirect']
        ? { back: route.data['redirect'] }
        : undefined,
    });
    return false;
  }
}
