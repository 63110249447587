import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {
    transform(value: string = ''): string {
        return value
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
    }
}
