import { CountryCode } from 'libphonenumber-js';
import { environment } from 'src/environments/environment';

const standardUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const localUrl = /^https?:\/\/(localhost|127\.0\.0\.1)(:\d+)?(\/|$)/i;

export const appConfig = {
  name: `${environment.name}`,
  caption: `${environment.caption}`,
  domain: `${environment.domain}`,
  client: `${environment.client}`,
  production: environment.production,
  portal: {
    url: `${environment.portal.url}`,
  },
  website: {
    url: `${environment.website.url}`,
  },
  page: {
    titlePrefix: `${environment.page.titlePrefix}`,
    author: `${environment.page.author}`,
    publisher: `${environment.page.publisher}`,
    robots: `${environment.page.robots}`,
  },
  urlPattern: new RegExp(`${standardUrl.source}|${localUrl.source}`),
  countryCode: 'GB' as CountryCode,
  featuredCountryCodes: ['GB'] as CountryCode[]
};
