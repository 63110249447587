import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  constructor() { }

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['shouldReuse'] || false;
  }

  store(route: ActivatedRouteSnapshot, handle: object): void {
    if (route.data['shouldReuse'] && route.routeConfig) {
      route.routeConfig.path && (this.handlers[route.routeConfig.path] = handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && (route.routeConfig.path ? !!this.handlers[route.routeConfig.path] : false);
  }

  retrieve(route: ActivatedRouteSnapshot): object | null {
    if (!route.routeConfig) return null;
    return route.routeConfig.path ? this.handlers[route.routeConfig.path] : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const shouldReuse = (future.routeConfig === curr.routeConfig) && future.data['reuseComponent'];
    if (typeof shouldReuse === "undefined") return true;
    return shouldReuse;
  }

}