import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecaseExtended'
})
export class TitleCaseExtendedPipe implements PipeTransform {
  private EXCEPTIONS = ['UK', 'KM', 'HCUK', "10KM", "5KM", '5K', '10K', '15K', 'RunThrough'];
  private SMALL_WORDS = /^(a|an|and|as|at|but|by|en|for|if|in|of|on|or|the|to|vs?\.?|via)$/i;

  constructor(private titleCasePipe: TitleCasePipe) { }

  transform(value: string | undefined, exceptions: string[] = []): string {
    let result = '';

    if (value) {
      const words = value.split(' ');

      exceptions = exceptions.concat(this.EXCEPTIONS);

      result = words.map(word => {
        const exception = exceptions.find(text => text.toLowerCase() === word.toLowerCase());

        if (exception) {
          return exception;
        }

        return this.SMALL_WORDS.test(word) ? word.toLowerCase() : this.titleCasePipe.transform(word);
      }).join(' ');
    }

    return result;
  }
}
